// Global styling goes here
html {
  height: 100%;
}

body {
  background-color: #fafbff !important;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

h1 {
  font-weight: 700;
  font-size: '1.875rem'; // 30px
  line-height: 1.302; // 39.06px
  font-family: "Inter", sans-serif !important;

}

h2 {
  font-weight: 700;
  font-size: '1.5rem'; // 24px
  line-height: 1.302; // 31.25px
  font-family: "Inter", sans-serif !important;

}

h3 {
  font-weight: 700;
  font-size: '1.25rem'; // 20px
  line-height: 1.302; //26.04px
  font-family: "Inter", sans-serif !important;
}


h4 {
  font-weight: 700;
  font-size: '1.25rem'; // 20px
  line-height: 1.21; // 24.2px
  font-family: "Inter", sans-serif !important;
}

h5 {
  font-weight: 500;
  font-size: '1.125rem'; // 18px
  line-height: 1.302; // 23.44px
  font-family: "Inter", sans-serif !important;

}

h6 {
  font-weight: 500;
  font-size: '1rem'; // 16px
  line-height: 1.301; // 20.83px
  font-family: "Inter", sans-serif !important;

}

.subtitle1 {
  font-weight: 400;
  font-size: '1.125rem'; // 18px
  line-height: 1.302; //23.44px
  font-family: "Inter", sans-serif !important;

}

.subtitle2 {
  font-weight: 700;
  font-size: '0.875rem'; // 14px
  line-height: 1.302; // 18.23px
  font-family: "Inter", sans-serif !important;

}

.MuiTypography-body1 {
  font-weight: 700;
  font-size: '1rem'; // 16px
  line-height: 1.301; // 20.83px
  font-family: "Inter", sans-serif !important;

}

.MuiTypography-body2 {
  font-weight: 500;
  font-size: 0.5rem; // 14px
  line-height: 1.302; // 18.23px
  font-family: "Inter", sans-serif !important;

}

.MuiLink-root {
  font-weight: 500;
  font-size: 0.875rem !important; // 14px
  line-height: 1.302; // 18.23px
  font-family: "Inter", sans-serif !important;

}

button {
  font-weight: 500;
  font-size: '1.125rem'; // 18px
  line-height: 1.302; // 23.44px
  text-transform: 'none';
  font-family: "Inter", sans-serif !important;

}

.wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.css-1832g42 {
  //background-image: url('./assets/images/banner.JPG') !important;
}

.content-wrapper {
  padding-top: 80px;
  padding-bottom: 40px;
}


.MuiInputBase-sizeSmall {
  font-weight: 400;
  font-size: 1rem;
  font-family: DM Sans, Inter, sans-serif;
  color: #242731;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 10px !important;
}

.MuiTypography-body1.MuiListItemText-primary.css-1c06rxt-MuiTypography-root {
  font-size: 1rem;
}
h1 {
  font-size: 24px;
}

p {
  font-size: 16px;
}

.initial-setup {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  // background-color: #f0f8ffb8 !important;
  display: flex !important;
  align-items: center;
  justify-content: stretch;
  background-color: #ffffff;
  color: #333333;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 16px;
  color: rgb(30, 68, 98);
  // border: 1px solid rgb(77, 171, 245);
}

.setup-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  display: flex !important;
  align-items: center;
  justify-content: stretch;
  background-color: #ffffff;
  color: #333333;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  color: rgb(30, 68, 98);
  border-radius: 10px !important;
  padding: 30px !important;
}



.setup-btn {
  background: #5E5DF0;
  border-radius: 999px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;

}

.setup-btn:hover {
  box-shadow: rgb(131 128 219 / 50%) 0 3px 8px;
}

.home-profile-card {
  position: relative;
  margin-top: 20px;
  border: 1px solid #e7e7f1;
  border-radius: 10px !important;
  background: #fff;
  padding: 20px 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
.login-page {
  display: block;

  h1 {
    font-size: 24px;
    text-align: center;
  }

  .login-container {
    width: 100%;
    max-width: 480px;
    border: 1px solid rgb(225, 227, 227);
    border-radius: 5px;
    padding: 10px 25px 30px 25px;
    background-color: #ffffff;
    margin: 0 auto;

    .login-field {
      display: block;
      margin-bottom: 25px;

      input {
        display: block;
        width: calc(100% - 30px);
        padding: 15px;
        border: 1px solid rgb(225, 227, 227);
        border-radius: 5px;
        font-size: 16px;
      }      
    }
  }
}
.forgot {
  font-size: 16px;
  font-weight: bold;
  color: #4966e2;
  text-decoration: none;
}
.submit-btn {
  width: 100%;
  display: block;
  text-align: center;
  border: none;
  border-radius: 5px;
  padding: 15px;
  background-color: #4966e2;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
.header {
  background-color: hsla(0, 0%, 100%, 0.6);
  padding: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  height: 60px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;

}

.header-left {
  max-width: 100px;
  float: left;
  color: #fff;

  img {
    display: block;
    max-width: 125px;
  }
}
.header-right {
  max-width: calc(100% - 100px);
  float: right;
}
.nav-list {
  display: block;
  list-style:none;
  margin: 0;
}
.nav-list li{
  float: left;
  padding: 0 15px;
  color: #ffffff;
}
.nav-list li a{
  color: #ffffff;
  text-decoration: none;
}
.nav-list li a.active{
  color: #ce9327;
}

.css-hyum1k-MuiToolbar-root {
  background-color: #ffffff !important;
}
.logo {
  display: block;
  max-width: 125px;
}
.menu-link:hover {
  border-radius: 5px !important;
}

header {
  background-color: hsla(0, 0%, 100%, 0.6) !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  backdrop-filter: blur(8px) !important;
  border-bottom: 0!important;
}
.footer {
  background-color: inherit;
  margin-top: 20px;
  padding: 15px 0;
  width: 100%;
  // position: absolute;
  // left: 0;
  // bottom: 0;
  // z-index: 1000;
}

.footer p {
  color: #8f8f8f;
  font-size: 14px;
  text-align: center;
  margin: 0;
}
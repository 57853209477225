.login-page {
  display: block;
}
.link {
  text-decoration: none !important;
  color: #4966e2 !important;
}

.forgot {
  font-size: 16px;
  font-weight: bold;
  color: #4966e2;
  text-decoration: none;
}
.submit-btn {
  width: 100%;
  display: block;
  text-align: center;
  border: none;
  border-radius: 5px;
  padding: 15px;
  background-color: #4966e2;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}